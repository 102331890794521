import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import { toastError, toastSuccess } from '../../components/toasts/toasts';

import './ChooseSlot.css';
import { bookSlotRound2TD } from '../../apis/round2Api.js';

const ChooseSlot = ({ slots, closeModal, selectedCandidate }) => {

	const [selectedSlot, setSelectedSlot] = useState(null);
	const [groupedSlots, setgroupedSlots] = useState([]);
	const [loading, setLoading] = useState(true);
	const token = cookie.load('token');

	useEffect(() => {
		setLoading(true);

		let hash = Object.create(null);
		let groupedSlots = [];

		slots.forEach((slot) => {
			if(!hash[slot.date]) {
				hash[slot.date] = [];
				groupedSlots.push(hash[slot.date]);
			}
			hash[slot.date].push(slot);
		});

		setgroupedSlots(groupedSlots);
		setLoading(false);
	}, []);

	const finalizeSlot = async () => {
		setLoading(true);

		let coreDomain = "";
		let coreDomains = selectedCandidate.User.coreDomains;

		for(let i=0; i<coreDomains.length; i++) {
			if(coreDomains[i] === "Tech" || coreDomains[i] === "Design") {
				coreDomain = coreDomains[i];
				break;
			}
		}

		if(selectedSlot === null) {
			toastError("Please select a slot!");
			setLoading(false);
			return;
		}

		await bookSlotRound2TD(token, coreDomain, selectedSlot.suid, selectedCandidate.regNo)
			.then((response) => {
				console.log("response of bookslot2td",response);
				if(!response.status) {
					toastSuccess(response.message);
					window.location.reload();
				} else {
					toastError(response.message);
				}
				setLoading(false);
				closeModal();
		})
		.catch((err) => {
			console.log(err);
			setLoading(false);
		});
	}

	if(loading) {
		return <div style={{'color': 'white'}}>Loading....</div>
	}
	return(
		<div className="modal-container">
			{
				groupedSlots.map((data) => {
					return(
						<>
							<div className = "modal-slot-heading">{data[0].date}</div>
							{
								data.map((d) => {
									if(selectedSlot!=null && selectedSlot.suid === d.suid) {
										return (
											<button className="candidate-table-row-data-interview slot-selected" style={{"marginTop":"3px"}} type="button" onClick={() => setSelectedSlot(d.suid)}>
											{d.timeFrom+"-"+d.timeTo}
										</button>
										);
									}
									return (
										<button className="candidate-table-row-data-interview"  style={{"marginTop":"3px"}} type="button" onClick={() => setSelectedSlot(d)}>
											{d.timeFrom+"-"+d.timeTo}
										</button>
									);
								})
							}
						</>
					);
				})
			}
		<div className="modal-book-btn-container">
			<button className="candidate-table-row-data-interview"type="button" onClick={finalizeSlot}>Book</button>
		</div>
		</div>
	)
}

export default ChooseSlot;
