import React, { useState, useEffect } from "react";
// import axios from 'axios';
import cookie from "react-cookies";
import Loader from "react-loader-spinner";
import { toastError } from "../../components/toasts/toasts";

import { useHistory } from "react-router-dom";

import "./RoundOneScreen.css";
import Navbar from "../../components/Navbar/Navbar";
const RoundOneScreen = () => {
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getRound1ReadyCandidates();
  }, []);

  const getRound1ReadyCandidates = () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `Bearer ${cookie.load("token")}` },
    };
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/r1/candidates`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCandidates(response.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const onInterviewClick = (regNo) => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${cookie.load("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ candidates: [`${regNo}`] }),
    };
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/r1/candidates`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response === null) {
          toastError("Something went wrong");
        }

        if (response.success) {
          history.push("/amc/regNo=" + regNo);
        } else {
          toastError("Participant is already called or something went wrong");
        }
        setLoading(false);
      })
      .catch((e) => {
        toastError(e.response.data.message);
        console.log(e);
      });
  };

  if (loading) {
    return (
      <div className="roundonescreen-container center-loading">
        <Loader type="ThreeDots" color="#067EF5" />
      </div>
    );
  } else {
    return (
      <div className="roundonescreen-container">
        <Navbar />
        <button
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/dashboard")}
          className="goback-button"
        >
          Go back
        </button>
        <table className="candidate-table">
          <thead>
            <tr>
              {/* <th>Company</th> */}
              <th className="candidate-table-row-title">Name</th>
              <th className="candidate-table-row-title">Reg No</th>
              <th className="candidate-table-row-title">Domains</th>
              <th className="candidate-table-row-title">Interview</th>
            </tr>
          </thead>
          <tbody>
            {candidates ? (
              candidates.map((candidate, index) => (
                <tr key={index} className="candidate-table-row">
                  <td className="candidate-table-row-data">
                    {candidate.User.name}
                  </td>
                  <td className="candidate-table-row-data">
                    {candidate.User.regNo}
                  </td>
                  <td className="candidate-table-row-data">
                    {candidate.User.specificDomains.map((domain) => (
                      <span>{domain}, </span>
                    ))}
                  </td>
                  <td className="candidate-table-row-data">
                    <button
                      className="candidate-table-row-data-interview"
                      onClick={() => onInterviewClick(candidate.User.regNo)}
                    >
                      Interview
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <p>No candidates found.</p>
            )}
          </tbody>
        </table>
      </div>
    );
  }
};

export default RoundOneScreen;
