module.exports = {
  App: "App",
  Web: "Web",
  Ml: "ML",
  Elec: "Electronics",
  CSec: "CyberSecurity",
  ARVR: "AR/VR",
  TechDomains() {
    return [this.App, this.Web, this.Ml, this.Elec, this.CSec, this.ARVR];
  },
  GDes: "GraphicDesign",
  Ui: "UI/UX",
  Vfx: "VFX",
  ThreeD: "  3D",
  DesignDomains() {
    return [this.GDes, this.Ui, this.Vfx, this.ThreeD];
  },
};