import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import cookie from "react-cookies";
import Loader from "react-loader-spinner";
import { toastError } from "../../components/toasts/toasts";
import {
  gdaGetCandidates,
  gdaSubmitCandidateInfo,
} from "../../apis/round2Api.js";
import { getProjects } from "../../apis/amcApi.js";
const ManagementAmcScreen = () => {
  const params = useParams();
  const token = cookie.load("token");
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    setLoading(true);
    gdaGetCandidates(token, params.id)
      .then((data) => {
        data.forEach((candidate) => {
          setCandidates((prev) => [...prev, candidate]);
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    return () => {
      setCandidates([]);
    };
  }, []);

  useEffect(() => {
    getProjects(token, "Management")
      .then((projectData) => {
        console.log("Project Data: ", projectData);
        // setProjects(projectData);
        projectData.forEach((project) => {
          setProjects((prev) => [...prev, project]);
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      setProjects([]);
    };
  }, []);

  // const removeCandidate = (candidate) => {
  //   setLoading(true);
  //   const idx = candidates.indexOf(candidate);
  //   console.log(candidates);
  //   console.log(idx);
  //   if (idx > -1) {
  //     candidates.splice(idx, 1);
  //   }
  //   console.log(candidates);
  //   setCandidates(candidates);
  //   setLoading(false);
  // };

  if (loading) {
    return (
      <div className="amcscreen-container center-loading">
        <Loader type="ThreeDots" color="#067EF5" />
      </div>
    );
  } else {
    return (
      <div className="amcscreen-container">
        <Navbar />
        <>
          {candidates !== null && candidates.length ? (
            candidates.map((candidate) => {
              return (
                <CandidateManagmentAmc
                  candidate={candidate}
                  token={token}
                  // removeCandidate={removeCandidate}
                  setLoading={setLoading}
                  projects={projects}
                />
              );
            })
          ) : (
            <div>No Candidates</div>
          )}
        </>
      </div>
    );
  }
};

const CandidateManagmentAmc = ({
  candidate,
  token,
  // removeCandidate,
  setLoading,
  projects,
}) => {
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");
  const [btnLoading, setbtnLoading] = useState(false);
  const [projectGiven, setprojectGiven] = useState(-1);
  const submitCandidateInfo = (regNo) => {
    setLoading(true);
    if (projectGiven === -1) {
      toastError("Please select a project");
      setLoading(false);
      return;
    }
    if (comment === "") {
      toastError("Please enter a comment");
      setLoading(false);
      return;
    }
    console.log("Sending req. for " + regNo);
    console.log(comment, status, regNo);
    setbtnLoading(true);
    gdaSubmitCandidateInfo(token, comment, status, regNo, projectGiven)
      .then((data) => {
        console.log(data);
        setStatus("");
        setComment("");
        setbtnLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setbtnLoading(false);
        console.log(err);
      });
  };
  const handleProjectSelectChange = (e) => {
    console.log(e.target.value);
    setprojectGiven(e.target.value);
  };
  return (
    <div className="roundn-main-container" style={{ marginTop: "15px" }}>
      <div className="roundn-container">
        <div className="roundn-details-container">
          <div className="roundn-details-heading">
            {candidate.User.name} - {candidate.User.regNo}
          </div>
          <textarea
            cols={50}
            rows={10}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          ></textarea>
        </div>
        <div className="roundn-choice-container">
          <div className="roundn-status-container">
            <div className="choice-container">
              <input
                type={"radio"}
                name="status"
                value={"yes"}
                checked={status === "AR"}
                onChange={() => setStatus("AR")}
              />{" "}
              <br />
              <label>Yes</label>
            </div>
            <div className="choice-container">
              <input
                type={"radio"}
                name="status"
                value={"no"}
                checked={status === "RR"}
                onChange={() => setStatus("RR")}
              />{" "}
              <br />
              <label>No</label>
            </div>
            <div className="choice-container">
              <input
                type={"radio"}
                name="status"
                value={"idk"}
                checked={status === "ER"}
                onChange={() => setStatus("ER")}
              />{" "}
              <br />
              <label>I dont know</label>
            </div>
          </div>
          <div className="roundn-status-container">
            <select value={projectGiven} onChange={handleProjectSelectChange}>
              <option value={-1} disabled selected>
                Select an Option
              </option>
              {projects !== null && projects.length !== 0 ? (
                projects.map((project, i) => {
                  return (
                    <option key={i} value={project.puid}>
                      {project.title}
                    </option>
                  );
                })
              ) : (
                <>No Projects</>
              )}
            </select>
          </div>
        </div>
      </div>
      <div className="amc-submit-btn-container">
        <button
          type={"button"}
          disabled={btnLoading}
          className="amc-submit-button"
          onClick={() => submitCandidateInfo(candidate.User.regNo)}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ManagementAmcScreen;
