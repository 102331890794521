import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import Loader from "react-loader-spinner";
import Navbar from '../../components/Navbar/Navbar';
import { toastError, toastSuccess } from '../../components/toasts/toasts';

import './ProfileScreen.css';

const ProfileScreen = () => {

	const [adminInfo, setAdminInfo] = useState(null);
	const [loading, setLoading] = useState(true);
	const [editInfo, setEditInfo] = useState(false);
	const [editName, setEditName] = useState("");
	const [editPassword, setEditPassword] = useState("");
	const [editMeetLink, setEditMeetLink] = useState("");

	useEffect(() => {
		// setLoading(true);
		getAdminInfo();
	}, []);

	const getAdminInfo = () => {
		const requestOptions = {
			method: 'GET',
			headers: { 
				'Authorization': `Bearer ${cookie.load('token')}`
			}
		};
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/`, requestOptions)
			.then(response => response.json())
			.then(response => {
				setAdminInfo(response.data);
				console.log(response.data);
				setLoading(false);
			})
			.catch(e => console.log(e));
	}

	const updateInfo = () => {
		setEditInfo(false);
		console.log(editName, editMeetLink);
		const requestOptions = {
			method: 'PUT',
			headers: { 
				'Authorization': `Bearer ${cookie.load('token')}`,
				'Content-Type': 'application/json'
		},
			body: JSON.stringify({ 
				name: editName,
				meetLink: editMeetLink
			 })
		};
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/`, requestOptions)
			.then(response => response.json())
			.then(response => {
				if(!response.status) {
					window.location.reload();
					toastSuccess(response.message);
				} else {
					toastError(response.message);
				}
			})
			.catch(e => console.log(e));
	}

	if(loading) {
		return(
			<div className="profilescreen-container center-loading">
				<Loader type="ThreeDots" color="#067EF5" />
			</div>
		);
	} else {
		return(
			<div className="profilescreen-container">
				<Navbar />
				<h2>PROFILE</h2>
				<br/>
				{
					editInfo ? (
						<button className="good-looking-button" onClick={() => updateInfo()}>
							Save
						</button>
						) : (
						<button
							className="good-looking-button"
							onClick={() => {
								setEditInfo(true);
								setEditName(adminInfo.name);
								setEditMeetLink(adminInfo.meetLink);
							}}
							>
							Edit Info
						</button>
					)
				}
				<div className="profilescreen-input-container">
					<label>Name</label>
					{
						editInfo
						? 
							<div>
								<input
									type="text"
									className="edit-input"
									value={editName}
									onChange={(e) => {
										setEditName(e.target.value);
									}}
								/>
							</div>
							
						:
							<p>{adminInfo.name}</p>
					}
				</div>
				<div className="profilescreen-input-container">
					<label>Email</label>
					<p>{adminInfo.email}</p>
				</div>
				<div className="profilescreen-input-container">
					<label>Meet link</label>
					{
						editInfo
						? 
							<div>
								<input
									type="text"
									className="edit-input"
									value={editMeetLink}
									onChange={(e) => {
										setEditMeetLink(e.target.value);
									}}
								/>
							</div>
						:
							<p>{adminInfo.meetLink}</p>
					}
				</div>
				
			</div>
		)
	}
}

export default ProfileScreen;