import React from 'react';
import cookie from 'react-cookies';

import './Navbar.css';

const Navbar = () => {

	const onDashboardClick = () => {
		window.location.href="/dashboard";
	}

    const onProfileClick = () => {
		window.location.href="/profile";
    }

    const onLogoutClick = () => {
		cookie.remove('token', { path: '/' });
		window.location.href="/";
    }

    return(
        <div className="navbar-container">
			<button
				onClick={onDashboardClick}
				className="navbar-button"
			>
				DASHBOARD
			</button>
			<button
				onClick={onProfileClick}
				className="navbar-button"
			>
				PROFILE
			</button>
			<button
				onClick={onLogoutClick}
				className="navbar-button"
			>
				LOG OUT
			</button>
		</div>
	)
}

export default Navbar;