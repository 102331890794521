 import activityLayerApi from "../apis/apiSetup.js";

export const getAdmin = (token) => {
  return new Promise((resolve, reject) => {
    activityLayerApi
      .get(`/api/admin/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
        reject("Kuch to gadbad hai daya, uhm!");
      }); 
  });
};

export const availableSlot = (token, domain, date, timeFrom, timeTo) => {
  return new Promise((resolve, reject) => {
    activityLayerApi
      .post(
        `/api/admin/slots/available`,
        {
          domain,
          date,
          timeFrom,
          timeTo,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp.data.message);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data.message);
      });
  });
};
