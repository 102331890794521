import React, { useState, useEffect } from "react";
// import axios from 'axios';
import cookie from "react-cookies";
import Loader from "react-loader-spinner";

import { useHistory } from "react-router-dom";

import "./ExceptionsScreen.css";
import Navbar from "../../components/Navbar/Navbar";

const ExceptionsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getExceptionCandidates();
  }, []);

  const getExceptionCandidates = () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `Bearer ${cookie.load("token")}` },
    };
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/exceptions`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCandidates(response.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const onInterviewClick = (regNo) => {
    console.log(regNo);
    // setLoading(true);
    // const requestOptions = {
    // 	method: 'POST',
    // 	headers: {
    // 		'Authorization': `Bearer ${cookie.load('token')}`,
    // 		'Content-Type': 'application/json'
    // },
    // 	body: JSON.stringify({ candidates: [`${regNo}`] })
    // };
    // fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/exceptions`, requestOptions)
    // 	.then(response => response.json())
    // 	.then(response => {
    // 		console.log(response);
    // 		setLoading(false);
    // 	})
    // 	.catch(e => console.log(e));
  };

  if (loading) {
    return (
      <div className="exceptionsscreen-container center-loading">
        <Loader type="ThreeDots" color="#067EF5" />
      </div>
    );
  } else {
    return (
      <div className="exceptionsscreen-container">
        <Navbar />
        <h1>Excpetion Screen</h1>
        <button
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/dashboard")}
          className="goback-button"
        >
          Go back
        </button>
        <table className="candidate-table">
          <thead>
            <tr>
              {/* <th>Company</th> */}
              <th className="candidate-table-row-title">Name</th>
              <th className="candidate-table-row-title">Reg No</th>
              <th className="candidate-table-row-title">Domains</th>
              <th className="candidate-table-row-title">Interview</th>
            </tr>
          </thead>
          <tbody>
            {
              // eslint-disable-next-line array-callback-return
              candidates ? (
                candidates.map((candidate, index) => (
                  <tr key={index} className="candidate-table-row">
                    <td className="candidate-table-row-data">
                      {candidate.User.name}
                    </td>
                    <td className="candidate-table-row-data">
                      {candidate.User.regNo}
                    </td>
                    <td className="candidate-table-row-data">
                      {candidate.User.specificDomains.map((domain) => (
                        <span>{domain}, </span>
                      ))}
                    </td>
                    <td className="candidate-table-row-data">
                      <button
                        className="candidate-table-row-data-interview"
                        onClick={() => {
                          history.push("/amc/regNo=" + candidate.User.regNo);
                        }}
                      >
                        Interview
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <p>No candidates found</p>
              )
            }
          </tbody>
        </table>
      </div>
    );
  }
};

export default ExceptionsScreen;
