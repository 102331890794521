import React, { useState, useEffect } from "react";
import cookie from "react-cookies";
import Loader from "react-loader-spinner";
import { getAdmin } from "../../apis/user";
import Navbar from "../../components/Navbar/Navbar";

import "./DashboardScreen.css";

const DashboardScreen = () => {
  const [loading, setLoading] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    getAdminInfo();
  }, []);
  const token = cookie.load("token");
  const getAdminInfo = async () => {
    setLoading(true);
    await getAdmin(token)
      .then((data) => {
        console.log(data.superUser);
        setIsSuperAdmin(data.superUser);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Round3 = () => {
    return (
      <div className="roundbuttons-inner-div">
        <p className="roundbuttons-inner-div-header">Round 3</p>
        <hr />
        <div className="roundbuttons-inner-div-body">
          <button
            onClick={() => {
              window.location.href = "/round3";
            }}
            className="round-button"
          >
            Round 3
          </button>
        </div>
      </div>
    );
  };
  if (loading) {
    return (
      <div className="roundonescreen-container center-loading">
        <Loader type="ThreeDots" color="#067EF5" />
      </div>
    );
  } else {
    return (
      <div className="dashboardscreen-container">
        <Navbar />
        <div className="dashboardscreen-header-titles">
          <p className="dashboardscreen-dontmessup">Don't mess up</p>
          <h4>
            Do not progress if confused. Call up someone from the board to
            figure stuff out.
          </h4>
        </div>
        <div className="roundbuttons-div">
          {/* <div className="roundbuttons-inner-div">
            <p className="roundbuttons-inner-div-header">Round 1</p>
            <hr />
            <div className="roundbuttons-inner-div-body">
              <button
                onClick={() => {
                  window.location.href = "/round1";
                }}
                className="round-button"
              >
                See list
              </button>
            </div>
          </div> */}
          <div className="roundbuttons-inner-div">
            <p className="roundbuttons-inner-div-header">Round 2 (M)</p>
            <hr />
            <div className="roundbuttons-inner-div-body">
              <button
                onClick={() => {
                  window.location.href = "/management-pool";
                }}
                className="round-button"
              >
                Pool List
              </button>
              <button
                onClick={() => {
                  window.location.href = "/management-gd";
                }}
                className="round-button"
              >
                GD List
              </button>
            </div>
          </div>
          <div className="roundbuttons-inner-div">
            <p className="roundbuttons-inner-div-header">Round 2 (T/D)</p>
            <hr />
            <div className="roundbuttons-inner-div-body">
              <button
                onClick={() => {
                  window.location.href = "/round2-td";
                }}
                className="round-button"
              >
                See list
              </button>
            </div>
          </div>
          {isSuperAdmin ? <Round3 /> : ""}
          <div className="roundbuttons-inner-div">
            <p className="roundbuttons-inner-div-header">Additional</p>
            <hr />
            <div className="roundbuttons-inner-div-body">
              {isSuperAdmin ? (
                <button
                  onClick={() => {
                    window.location.href = "/exception";
                  }}
                  className="round-button"
                >
                  Exception list
                </button>
              ) : (
                ""
              )}

              <button
                onClick={() => {
                  window.location.href = "/viewSlots";
                }}
                className="round-button"
              >
                Slots List
              </button>
              {/* <button
                onClick={() => {
                  window.location.href = "/mySlots";
                }}
                className="round-button"
              >
                My Slots
              </button>

              <button
                onClick={() => {
                  window.location.href = "/addSlots";
                }}
                className="round-button"
              >
                Add Slots
              </button> */}
              {isSuperAdmin ? (
                <button
                  onClick={() => {
                    window.location.href = "/on-going-meetings";
                  }}
                  className="round-button"
                >
                  On going meetings
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default DashboardScreen;
