import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

import LoginScreen from './screens/LoginScreen/LoginScreen';
import MeetScreen from './screens/MeetScreen/MeetScreen';
import DashboardScreen from './screens/DashboardScreen/DashboardScreen';
import ProfileScreen from './screens/ProfileScreen/ProfileScreen';
import ManagementPoolScreen from './screens/ManagementPoolScreen/ManagementPoolScreen';
import ManagementPoolCandidateListScreen from './screens/ManagementPoolCandidateListScreen/ManagementPoolCandidateListScreen';
import ManagementGDListScreen from './screens/ManamentGDListScreen/ManamentGDListScreen';
import SignUpScreen from './screens/SignUpScreen/SignUpScreen';
import RoundOneScreen from './screens/RoundOneScreen/RoundOneScreen';
import AmcScreen from './screens/AmcScreen/AmcScreen';
import RoundTwoTDScreen from './screens/RoundTwoTDScreen/RoundTwoTDScreen';
import RoundThreeListScreen from './screens/RoundThreeListScreen/RoundThreeListScreen';
import ManagementAmcScreen from './screens/ManagementAmcScreen/ManagementAmcScreen.jsx';
import ExceptionsScreen from './screens/ExceptionsScreen/ExceptionsScreen';
import SlotsScreen from './screens/SlotsScreen/SlotsScreen';
// import MySlotsScreen from './screens/MySlots/MySlotsScreen';
import AddSlotsScreen from './screens/AddSlotsScreen/AddSlotsScreen';
import OnGoingMeetingsListScreen from './screens/OnGoingMeetingsListScreen/OnGoingMeetingsListScreen';

const AppRouter = () => {
	return (
		<div>
			<ToastContainer position="bottom-right" autoClose={3000} />
			<BrowserRouter>
				<Switch>
					<Route path = '/' component={LoginScreen} exact />
					<Route path = '/signup' component={SignUpScreen} exact />
					<Route path = '/meet' component={MeetScreen} exact />
					<Route path = '/profile' component={ProfileScreen} exact />
					<Route path = '/dashboard' component={DashboardScreen} exact />
					<Route path = '/round1' component={RoundOneScreen} exact />
					<Route path = '/round2-td' component={RoundTwoTDScreen} exact />
					<Route path = '/management-pool' component={ManagementPoolScreen} exact />
					<Route path = '/management-pool/:suid' component={ManagementPoolCandidateListScreen} exact />
					<Route path = '/management-gd' component={ManagementGDListScreen} exact />
					<Route path = '/management-gd/:id' component={ManagementAmcScreen} exact />
					<Route path = '/round3' component={RoundThreeListScreen} exact />
					<Route path = '/amc/regNo=:regNo' component={AmcScreen} exact />
					<Route path = '/exception' component={ExceptionsScreen} exact />
					<Route path = '/viewSlots' component={SlotsScreen} exact />
					{/* <Route path = '/mySlots' component={MySlotsScreen} exact /> */}
					<Route path = '/addSlots' component={AddSlotsScreen} exact />
					<Route path = '/on-going-meetings' component={OnGoingMeetingsListScreen} exact />
				</Switch>
			</BrowserRouter>
		</div>
	)
}

export default AppRouter;
