import React, { useState, useEffect } from "react";
import cookie from "react-cookies";
import Loader from "react-loader-spinner";
import Navbar from "../../components/Navbar/Navbar";
import { useHistory } from "react-router-dom";

import "./SlotsScreen.css";

const SlotsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getAllSlotsInfo();
  }, []);

  const getAllSlotsInfo = () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `Bearer ${cookie.load("token")}` },
    };
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/allslots?roundNo=2&roundNo=3`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setSlots(response.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  if (loading) {
    return (
      <div className="roundonescreen-container center-loading">
        <Loader type="ThreeDots" color="#067EF5" />
      </div>
    );
  } else {
    return (
      <div className="roundtwotdscreen-container">
        <Navbar />
        <button
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/dashboard")}
          className="goback-button"
        >
          Go back
        </button>
        <table className="candidate-table">
          <thead>
            <tr>
              {/* <th>Company</th> */}
              <th className="candidate-table-row-title">Date</th>
              <th className="candidate-table-row-title">Time</th>
              <th className="candidate-table-row-title">Limit</th>
              <th className="candidate-table-row-title">Count</th>
              <th className="candidate-table-row-title">Round</th>
              <th className="candidate-table-row-title">Domain</th>
              <th className="candidate-table-row-title">Management</th>
              {/* <th className="candidate-table-row-title">Interview</th> */}
            </tr>
          </thead>
          <tbody>
            {slots ? (
              slots.map((slot, index) => (
                <tr key={index} className="candidate-table-row">
                  <td className="candidate-table-row-data">{slot.date}</td>
                  <td className="candidate-table-row-data">
                    {slot.timeFrom + "-" + slot.timeTo}
                  </td>
                  <td className="candidate-table-row-data">{slot.limit}</td>
                  <td className="candidate-table-row-data">{slot.count}</td>
                  <td className="candidate-table-row-data">{slot.roundNo}</td>
                  <td className="candidate-table-row-data">{slot.techDomain}</td>
                  <td className="candidate-table-row-data">{slot.mgmt.toString()}</td>
                </tr>
              ))
            ) : (
              <p>No candidates found.</p>
            )}
          </tbody>
        </table>
      </div>
    );
  }
};

export default SlotsScreen;
