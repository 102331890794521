import React, { useState, useEffect } from "react";
// import axios from 'axios';
import cookie from "react-cookies";
import Loader from "react-loader-spinner";
import { getOngoingMeetings, getOngoingMeetingsR3 } from "../../apis/amcApi.js";

import { useHistory } from "react-router-dom";

import "./OnGoingMeetingsListScreen.css";
import Navbar from "../../components/Navbar/Navbar";
import { toastError, toastSuccess } from "../../components/toasts/toasts";
const OnGoingMeetingsListScreen = () => {
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [candidatesR3, setCandidatesR3] = useState([]);
  const history = useHistory();

  useEffect(() => {
    fetchOnGoingMeetings();
    fetchOnGoingMeetingsR3();
  }, []);

  const fetchOnGoingMeetings = () => {
    setLoading(true);
    getOngoingMeetings(cookie.load("token"))
      .then((data) => {
        setCandidates(data);
        // toastSuccess("GG, you're a board member. Here are all ongoing meetings.");
        setLoading(false);
      })
      .catch((resp) => {
        console.log(resp);
        toastError(resp + "! Only accessed by board members.");
        setLoading(false);
      });
  };
  const fetchOnGoingMeetingsR3 = () => {
    setLoading(true);
    getOngoingMeetingsR3(cookie.load("token"))
      .then((data) => {
        setCandidatesR3(data);
        // toastSuccess("GG, you're a board member. Here are all ongoing meetings.");
        setLoading(false);
      })
      .catch((resp) => {
        console.log(resp);
        toastError(resp + "! Only accessed by board members.");
        setLoading(false);
      });
  };

  // const onInterviewClick = (regNo) => {
  // 	setLoading(true);
  // 	const requestOptions = {
  // 		method: 'POST',
  // 		headers: {
  // 			'Authorization': `Bearer ${cookie.load('token')}`,
  // 			'Content-Type': 'application/json'
  // 	},
  // 		body: JSON.stringify({ candidates: [`${regNo}`] })
  // 	};
  // 	fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/r1/candidates`, requestOptions)
  // 		.then(response => response.json())
  // 		.then(response => {
  // 			console.log(response);
  // 			setLoading(false);
  // 			history.push("/amc/regNo="+regNo);
  // 		})
  // 		.catch(e => console.log(e));
  // }

  if (loading) {
    return (
      <div className="roundonescreen-container center-loading">
        <Loader type="ThreeDots" color="#067EF5" />
      </div>
    );
  } else {
    return (
      <div className="roundonescreen-container">
        <Navbar />
        <button
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/dashboard")}
          className="goback-button"
        >
          Go back
        </button>
        <table className="candidate-table">
          <thead>
            <tr>
              {/* <th>Company</th> */}
              <th className="candidate-table-row-title">Name</th>
              <th className="candidate-table-row-title">Reg No</th>
              <th className="candidate-table-row-title">Domains</th>
              <th className="candidate-table-row-title">Admin</th>
              <th className="candidate-table-row-title">Link</th>
            </tr>
          </thead>
          <tbody>
            {candidates ? (
              candidates.map((candidate, index) => (
                <tr key={index} className="candidate-table-row">
                  <td className="candidate-table-row-data">
                    {candidate.User.name}
                  </td>
                  <td className="candidate-table-row-data">
                    {candidate.regNo}
                  </td>
                  <td className="candidate-table-row-data">
                    {candidate.User.specificDomains.map((domain) => (
                      <span>{domain}, </span>
                    ))}
                  </td>
                  <td className="candidate-table-row-data">
                    {candidate.Admin.name}
                  </td>
                  <td className="candidate-table-row-data">
                    <a href={candidate.Admin.meetLink}>Link</a>
                  </td>
                  {/* <td  className="candidate-table-row-data">
											<button className="candidate-table-row-data-interview" onClick={() => onInterviewClick(candidate.User.regNo)}>Interview</button>
										</td> */}
                </tr>
              ))
            ) : (
              <p>No candidates found.</p>
            )}
            {candidatesR3 ? (
              candidatesR3.map((candidate, index) => (
                <tr key={index} className="candidate-table-row">
                  <td className="candidate-table-row-data">
                    {candidate.User.name}
                  </td>
                  <td className="candidate-table-row-data">
                    {candidate.regNo}
                  </td>
                  <td className="candidate-table-row-data">
                    {candidate.User.specificDomains.map((domain) => (
                      <span>{domain}, </span>
                    ))}
                  </td>
                  <td className="candidate-table-row-data">
                    {candidate.Admin.name}
                  </td>
                  <td className="candidate-table-row-data">
                    <a href={candidate.Admin.meetLink}>Link</a>
                  </td>
                  {/* <td  className="candidate-table-row-data">
											<button className="candidate-table-row-data-interview" onClick={() => onInterviewClick(candidate.User.regNo)}>Interview</button>
										</td> */}
                </tr>
              ))
            ) : (
              <p>No candidates in R3 found.</p>
            )}
          </tbody>
        </table>
      </div>
    );
  }
};

export default OnGoingMeetingsListScreen;
