import React, { useState } from "react";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { toastError, toastSuccess } from "../../components/toasts/toasts";

import "./SignUpScreen.css";

const SignUpScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleSubmit = () => {
    if (name === null || name === "") {
      toastError("Enter name");
      return;
    }
    if (email === null || email === "") {
      toastError("Enter email");
      return;
    }
    if (!password.length > 8) {
      toastError("Password should be greater than 8 characters");
      return;
    }
    setIsSubmitLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/adminregister`,
        {
          name,
          email,
          password,
        },
        {
          headers: {
            key: process.env.REACT_APP_CREATE_SLOTS,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          toastSuccess(
            "You have successfully registered. Contact support for admin approval"
          );
          setIsSubmitLoading(false);
          // window.location.href="/";
        } else {
          toastError(response.data.message);
        }
      })
      .catch((e) => {
        toastError(e.response.data.message);
        console.log(e);
      });
  };

  return (
    <div className="loginpage-container">
      <h2>Sign up page</h2>
      <div className="loginpage-login-container">
        <input
          type="text"
          placeholder="Enter name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <button onClick={handleSubmit} style={{ cursor: "pointer" }}>
          {isSubmitLoading ? <CircularProgress /> : <>Submit</>}
        </button>

        <a href="/">Login page</a>
      </div>
    </div>
  );
};

export default SignUpScreen;
