import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import cookie from 'react-cookies';
import Loader from "react-loader-spinner";
import Navbar from '../../components/Navbar/Navbar';
import { toastError, toastSuccess } from '../../components/toasts/toasts';

import './ManagementPoolScreen.css';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
		role="tabpanel"
		hidden={value !== index}
		id={`full-width-tabpanel-${index}`}
		aria-labelledby={`full-width-tab-${index}`}
		{...other}
		>
		{value === index && (
			<Box p={3}>
			<Typography>{children}</Typography>
			</Box>
		)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		//backgroundColor: theme.palette.background.paper,
		backgroundColor: 'black',
		//width: 500,
		primary: "blue",
	},
}));

const ManagementPoolScreen = () => {

	const classes = useStyles();
	const theme = useTheme();
	const [value, setValue] = useState(0);
	const [loading, setLoading] = useState(false);
	const [unassignedSlotsInfo, setUnassignedSlotsInfo] = useState([]);
	const [assignedSlotsInfo, setAssignedSlotsInfo] = useState([]);
	const [link, setLink] = useState("");
	const [edit, setEdit] = useState(false);
	const [editId, setEditId] = useState("");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index) => {
		setValue(index);
	};

	useEffect(() => {
		getSlots();
		getAssignedSlots();
	}, []);

	const getSlots = () => {
		setLoading(true);
		const requestOptions = {
			method: 'GET',
			headers: { 'Authorization': `Bearer ${cookie.load('token')}` },
		};
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/r2/mgmt/fetchUnoccupiedMgmtGdpSlots`, requestOptions)
			.then(response => response.json())
			.then(response => {
				console.log(response);
				setUnassignedSlotsInfo(response.data);
			})
			.catch(e => console.log(e));
	};

	const getAssignedSlots = () => {
		setLoading(true);
		const requestOptions = {
			method: 'GET',
			headers: { 'Authorization': `Bearer ${cookie.load('token')}` },
		};
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/r2/mgmt/fetchOccupiedMgmtGdpSlots`, requestOptions)
			.then(response => response.json())
			.then(response => {
				console.log(response);
				setAssignedSlotsInfo(response.data);
				setLoading(false);
			})
			.catch(e => console.log(e));
	}

	const setSlot = (suid) => {
		setLoading(true);
		const requestOptions = {
			method: 'POST',
			headers: { 
				'Authorization': `Bearer ${cookie.load('token')}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				gdpLink: link,
				suid: suid.toString()
			})
		};
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/mgmt/r2/gdp`, requestOptions)
			.then(response => response.json())
			.then(response => {
				console.log(response);
				if(!response.status) {
					toastSuccess(response.message);
					window.location.reload();
				} else {
					toastError(response.message);
				}
				setLoading(false);
			})
			.catch(e => console.log(e));
	};

	if(loading) {
		return(
			<div className="managementpoolscreen-container center-loading">
				<Loader type="ThreeDots" color="#067EF5" />
			</div>
		);
	} else {
		return (
			<div className="managementpoolscreen-container">
				<Navbar />
				<h2>Pool list view</h2>
				<div className={classes.root}>
					<AppBar position="static" color="default">
						<Tabs
						value={value}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="fullWidth"
						aria-label="full width tabs example"
						>
						<Tab label="Unassigned" {...a11yProps(0)} />
						<Tab label="Assigned" {...a11yProps(1)} />
						</Tabs>
					</AppBar>
					<SwipeableViews
						axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
						index={value}
						onChangeIndex={handleChangeIndex}
					>
						<TabPanel value={value} index={0} dir={theme.direction}>
							<table className="candidate-table">
								<thead>
									<tr>
										{/* <th>Company</th> */}
										<th className="candidate-table-row-title">Slot</th>
										<th className="candidate-table-row-title">Count</th>
										<th className="candidate-table-row-title">Enter link</th>
										<th className="candidate-table-row-title">Set link</th>
									</tr>
								</thead>
								<tbody>
									{
										unassignedSlotsInfo ?
											unassignedSlotsInfo.map((slot, index) => 
												<tr key={index} className="candidate-table-row">
													<td className="candidate-table-row-data">{slot.date+" "+slot.timeFrom+" "+slot.timeTo}</td>
													<td className="candidate-table-row-data">{slot.count}</td>
													<td className="candidate-table-row-data">
														{edit && editId === slot.suid ? (
															<input
																type="text"
																className="edit-input"
																// value={link}
																onChange={(e) => {
																	setLink(e.target.value);
																}}
															/>
															) : (
															`Link to be set`
															)
														}
													</td>
													<td  className="candidate-table-row-data">
														{
															edit && editId === slot.suid ? (
																<button className="edit-bth-scrapper-list" onClick={() => setSlot(slot.suid)}>
																	Save
																</button>
																) : (
																<button
																	className="edit-bth-scrapper-list"
																	onClick={() => {
																		setEdit(true);
																		setEditId(slot.suid);
																	}}
																	>
																	Edit
																</button>
															)
														}
														{/* <button className="candidate-table-row-data-interview" onClick={() => }>Set Link</button> */}
													</td>
												</tr>
											)
										:
											<div>
												No slots found
											</div>
									}
								</tbody>
							</table>
						</TabPanel>
						<TabPanel value={value} index={1} dir={theme.direction}>
							<table className="candidate-table">
								<thead>
									<tr>
										{/* <th>Company</th> */}
										<th className="candidate-table-row-title">Slot</th>
										<th className="candidate-table-row-title">Count</th>
										<th className="candidate-table-row-title">Admin</th>
										<th className="candidate-table-row-title">Link</th>
										<th className="candidate-table-row-title">View</th>
									</tr>
								</thead>
								<tbody>
									{
										assignedSlotsInfo ? 
											assignedSlotsInfo.map((slot, index) => 
												<tr key={index} className="candidate-table-row">
													<td className="candidate-table-row-data">{slot.date+" "+slot.timeFrom+" "+slot.timeTo}</td>
													<td className="candidate-table-row-data">{slot.count}</td>
													<td className="candidate-table-row-data">{slot.Admin.name}</td>
													<td className="candidate-table-row-data">{slot.gdpLink}</td>
													<td  className="candidate-table-row-data">
														<button className="candidate-table-row-data-interview" onClick={() => window.location.href=`/management-pool/${slot.suid}`}>View</button>
													</td>
												</tr>
											)
										:
											<div>
												No slots found
											</div>
									}
								</tbody>
							</table>
						</TabPanel>
					</SwipeableViews>
				</div>
			</div>
		);
	}
}

export default ManagementPoolScreen;
