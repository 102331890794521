import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import cookie from 'react-cookies';
import Loader from "react-loader-spinner";
import Navbar from '../../components/Navbar/Navbar';
import { toastError, toastSuccess } from '../../components/toasts/toasts';

import './ManagementPoolCandidateListScreen.css';

const ManagementPoolCandidateListScreen = () => {

	let { suid } = useParams();
	const [loading, setLoading] = useState(true);
	const [gdpCandidates, setgdpCandidates] = useState([]);
	const [selectedData, setSelectedData] = useState([]);
	const [selectAdmin, setSelectAdmin] = useState("");
	const [admins, setAdmins] = useState([]);

	useEffect(() => {
		fetchGDPCandidates();
		fetchAllAdmins();
	}, []);

	const fetchGDPCandidates = () => {
		const requestOptions = {
			method: 'GET',
			headers: { 'Authorization': `Bearer ${cookie.load('token')}` },
		};
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/r2/mgmt/fetchGdpCandidates?suid=${suid}`, requestOptions)
			.then(response => response.json())
			.then(response => {
				setgdpCandidates(response.data);
				console.log(gdpCandidates);
			})
			.catch(e => console.log(e));
	}

	const fetchAllAdmins = () => {
		const requestOptions = {
			method: 'GET',
			headers: { 'Authorization': `Bearer ${cookie.load('token')}` },
		};
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/alladmins`, requestOptions)
			.then(response => response.json())
			.then(response => {
				setAdmins(response.data);
				console.log(admins);
				setLoading(false);
			})
			.catch(e => console.log(e));
	}

	const addSelectedData = (emp, index) => {
		setSelectedData((data) => [...data, emp]);
		console.log(selectedData);
	};

	const removeSelectedData = (index) => {
		const array = selectedData.filter((emp) => emp.index !== index);
		setSelectedData(array);
		console.log(selectedData);
	};

	const setGDA = () => {
		setLoading(true);
		console.log("Selected candidates->", selectedData);
		console.log(selectAdmin);
		const requestOptions = {
			method: 'POST',
			headers: { 
				'Authorization': `Bearer ${cookie.load('token')}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				candidates: selectedData,
				auid: selectAdmin
			})
		};
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/mgmt/r2/gda`, requestOptions)
			.then(response => response.json())
			.then(response => {
				console.log(response);
				if(!response.status) {
					toastSuccess(response.message);
					window.location.reload();
				} else {
					toastError(response.message);
				}
			})
			.catch(e => console.log(e));
	}

	const handleAdminSelectChange = (e) => {
		console.log(e.target.value);
		setSelectAdmin(e.target.value);
	}

	if(loading) {
		return(
			<div className="managementpoolcandidatelistscreen-container center-loading">
				<Loader type="ThreeDots" color="#067EF5" />
			</div>
		)
	} else {
		return(
			<div className="managementpoolcandidatelistscreen-container">
				<Navbar />
				<h2>Pool Members</h2>
				<div className="managementpoolcandidatelistscreen-inner-container">
					<select value={selectAdmin} onChange={handleAdminSelectChange}>
					<option>Select</option>
						{admins.map((admin, i) => {
							return(
								<option key={i} value={admin.auid}>{admin.name}</option>
							)
						})}
					</select>
					<button
						onClick={setGDA}
					>
						Select Admin
					</button>
				</div>
				<table className="candidate-table">
					<thead>
						<tr>
							<th className="candidate-table-row-title">Select</th>
							<th className="candidate-table-row-title">Name</th>
							<th className="candidate-table-row-title">Reg no</th>
							<th className="candidate-table-row-title">Phone no</th>
						</tr>
					</thead>
					<tbody>
						{
							gdpCandidates ?
								gdpCandidates.map((candidate, index) => 
									<tr key={index} className="candidate-table-row">
										<td>
											<input
												type="checkbox"
												onChange={(e) => {
													e.target.checked
														? addSelectedData(candidate.User.regNo, index)
														: removeSelectedData(index);
												}}
											/>
										</td>
										<td className="candidate-table-row-data">{candidate.User.name}</td>
										<td className="candidate-table-row-data">{candidate.User.regNo}</td>
										<td className="candidate-table-row-data">{candidate.User.phoneNo}</td>
									</tr>
								)
							:
								<div>
									No candidates found.
								</div>
						}
					</tbody>
				</table>
			</div>
		)
	}
}

export default ManagementPoolCandidateListScreen;
