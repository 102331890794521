import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import Loader from "react-loader-spinner";
import Navbar from '../../components/Navbar/Navbar';

import './ManagementGDListScreen.css';

const ManagementGDListScreen = () => {

	const [loading, setLoading] = useState(false);
	const [slotsInfo, setSlotsInfo] = useState([]);

	useEffect(() => {
		getSlots();
	}, []);

	const getSlots = () => {
		setLoading(true);
		const requestOptions = {
			method: 'GET',
			headers: { 'Authorization': `Bearer ${cookie.load('token')}` },
		};
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/r2/mgmt/fetchOnGoingGda`, requestOptions)
			.then(response => response.json())
			.then(response => {
				console.log(response);
				setSlotsInfo(response.data);
				setLoading(false);
			})
			.catch(e => console.log(e));
	}

	if(loading) {
		return(
			<div className="managementpoolscreen-container center-loading">
				<Loader type="ThreeDots" color="#067EF5" />
			</div>
		);
	} else {
		return (
			<div className="managementgdscreen-container">
				<Navbar />
				<h2>GD List</h2>
				<table className="candidate-table">
					<thead>
						<tr>
							{/* <th>Company</th> */}
							<th className="candidate-table-row-title">Admin name</th>
							<th className="candidate-table-row-title">Moderator name</th>
							<th className="candidate-table-row-title">GD Link</th>
							<th className="candidate-table-row-title">View</th>
						</tr>
					</thead>
					<tbody>
						{
							slotsInfo ? 
								slotsInfo.map((slot, index) => 
									<tr key={index} className="candidate-table-row">
										<td className="candidate-table-row-data">{slot.Admin.name}</td>
										<td className="candidate-table-row-data">{slot.Slot.Admin.name}</td>
										<td className="candidate-table-row-data"><a href={slot.Admin.meetLink}  target="_blank" rel="noopener">Link</a></td>
										<td  className="candidate-table-row-data">
											<button className="candidate-table-row-data-interview" onClick={() => window.location.href=`/management-gd/${slot.Admin.auid}`}>View</button>
										</td>
									</tr>
								)
							:
								<div>
									No GDs found
								</div>
						}
					</tbody>
				</table>
			</div>
		);
	}
}

export default ManagementGDListScreen;