import React, { useState, useEffect } from "react";
import cookie from "react-cookies";
import Loader from "react-loader-spinner";
import Navbar from "../../components/Navbar/Navbar";
import { useHistory } from "react-router-dom";
import { toastSuccess, toastError } from "../../components/toasts/toasts";
import { availableSlot } from "../../apis/user";
import "./AddSlotsScreen.css";

const AddSlotsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState([]);
  const history = useHistory();
  const [domain, setDomain] = useState("");
  const [slotDetailID, setSlotDetailID] = useState("");
  const [slotDetail, setSlotDetail] = useState({});
  useEffect(() => {
    getAllSlotsInfo();
  }, []);
  const getAllSlotsInfo = () => {
    setLoading(true);

    const requestOptions = {
      method: "GET",
      headers: { Authorization: `Bearer ${cookie.load("token")}` },
    };
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/slots`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log("Slots: ", response);
        setSlots(response.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const handleDomainChange = (e) => {
    setDomain(e.target.value);
  };
  const handleDateTimeChange = (e) => {
    setSlotDetailID(e.target.value);
    slots.forEach((slot) => {
      if (slot.suid.toString() === e.target.value.toString()) {
        setSlotDetail(slot);
      }
    });
  };

  const scheduleSlot = () => {
    if (slotDetailID === "") {
      toastError("Please enter slot details");
      return;
    }
    if (domain === "") {
      toastError("Please enter domain");
      return;
    }
    setLoading(true);

    availableSlot(
      cookie.load("token"),
      domain,
      slotDetail.date,
      slotDetail.timeFrom,
      slotDetail.timeTo
    )
      .then((response) => {
        if (!response.status) {
          toastSuccess(response);
          // window.location.reload();
        } else {
          toastError(response);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        toastError(e);
        setLoading(false);
      });
  };
  if (loading) {
    return (
      <div className="roundonescreen-container center-loading">
        <Loader type="ThreeDots" color="#067EF5" />
      </div>
    );
  } else {
    return (
      <div className="roundtwotdscreen-container">
        <Navbar />
        <h2>Add Your Slots for Round 2 Tech Domains</h2>
        <div className="" style={{ paddingLeft: "1em" }}>
          <table className="candidate-table">
            <thead>
              <tr>
                {/* <th>Company</th> */}
                <th className="candidate-table-row-title">Domain</th>
                <th className="candidate-table-row-title">Date Time</th>
                <th className="candidate-table-row-title">Submit</th>
                {/* <th className="candidate-table-row-title">Interview</th> */}
              </tr>
            </thead>
            <tbody>
              <tr className="candidate-table-row">
                <td className="candidate-table-row-data">
                  <select
                    className="select-domain"
                    value={domain}
                    onChange={handleDomainChange}
                  >
                    <option value="" disabled selected>
                      Select Domain
                    </option>
                    <option value="App Development">App Development</option>
                    <option value="Web Development">Web Development</option>
                    <option value="AI / ML">AI / ML</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Cybersecurity">Cybersecurity</option>
                    <option value="3D,AR/VR">3D,AR/VR</option>
                    <option value="Blockchain">Blockchain</option>
                    <option value="Competitive Coding">
                      Competitive Coding
                    </option>
                    <option value="Game Development">Game Development</option>
                    {/* <option value="Graphic Design">Graphic Design</option> */}
                    <option value="Design">Design</option>
                    {/* <option value="VFX/Video Editing">VFX/Video Editing</option> */}
                    {/* <option value="  3D Modelling"> 3D Modelling</option> */}
                  </select>
                </td>
                <td className="candidate-table-row-data">
                  <select
                    className="select-date"
                    value={slotDetailID}
                    onChange={handleDateTimeChange}
                  >
                    <option value="" disabled selected>
                      Select Date
                    </option>
                    {slots.map((slotDetails) => {
                      return (
                        <option value={slotDetails.suid}>
                          {slotDetails.date} {slotDetails.timeFrom}
                          {" - "}
                          {slotDetails.timeTo}
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td className="candidate-table-row-data">
                  <button
                    className="candidate-table-row-data-interview"
                    onClick={scheduleSlot}
                  >
                    Schedule
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
};

export default AddSlotsScreen;
