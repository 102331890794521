import { React, useEffect, useState } from "react";
import Tabbar from "../../components/Tabbar/Tabbar";
import Navbar from "../../components/Navbar/Navbar";
import { useParams, useHistory } from "react-router-dom";
import cookie from "react-cookies";
import Loader from "react-loader-spinner";
import { toastError, toastSuccess } from "../../components/toasts/toasts";

import {
  getUserAmcDetails,
  getProjects,
  postRound1Info,
  postRound2Info,
  postRound3Info,
} from "../../apis/amcApi.js";
import { gdaSubmitCandidateInfo } from "../../apis/round2Api.js";
import constants from "../../utils/constants";
import "./AmcScreen.css";

const AmcScreen = () => {
  const params = useParams();
  const history = useHistory();
  const token = cookie.load("token");
  const [userDetails, setUserDetails] = useState({});
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [btnLoading, setbtnLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");
  const [selectedDomains, setselectedDomains] = useState([]);
  const [projectGiven, setprojectGiven] = useState(-1);
  const [currRound, setcurrRound] = useState(-1);
  const [round2Management, setround2Management] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      await getUserAmcDetails(params.regNo, token)
        .then(async (userDetails) => {
          console.log(userDetails);
          setUserDetails(userDetails);
          await getProjects(token, "TechDesign")
            .then((data) => {
              if (data == null || data === "") {
                data = [];
              }
              setProjects(data);
              let project = "";
              if (data.length > 0) {
                project = data[0];
              }

              let round = -1;
              //   if (!userDetails.round1Data.meetingCompleted) {
              //     console.log("Round 1 set");
              //     round = 1;
              //     setcurrRound(1);
              //   } else {
              for (let i = 0; i < userDetails.round2Data.length; i++) {
                let data = userDetails.round2Data[i];
                console.log("Data: ", data);
                if (data.coreDomain === "TechDesign") {
                  console.log(data);
                  if (!data.meetingCompleted) {
                    setcurrRound(2);
                    console.log("Round 2 set");
                    console.log(currRound);
                    round = 2;
                    break;
                  }
                } else if (data.coreDomain === "Management") {
                  setcurrRound(2);
                  round = 2;
                  console.log("Round2 Management set!");
                  setround2Management(data);
                }
              }
              console.log(currRound);
              //if(currRound === -1) {
              if (userDetails.round3Data.roundNo) {
                console.log("Round 3 set");
                round = 3;
                setprojectGiven(project.puid);
                setcurrRound(3);
              }
              console.log(round);
              setcurrRound(round);

              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchUserDetails();
  }, []);

  const round0 = () => {
    if (userDetails.round0Data == null) {
      return <div>{"error"}</div>;
    } else {
      return (
        <div>
          {userDetails.round0Data.map((data) => {
            return (
              <div className="user-detail-container">
                <div className="user-detail-heading">
                  {data.Question.question}
                </div>
                <div className="user-detail">{data.answer}</div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  // const submitRound1Info = async () => {
  //   if (btnLoading) {
  //     return;
  //   }
  //   let techPresent = false;
  //   let designPresent = false;
  //   for (let i = 0; i < selectedDomains.length; i++) {
  //     if (constants.TechDomains().includes(selectedDomains[i])) {
  //       techPresent = true;
  //     }
  //     if (constants.DesignDomains().includes(selectedDomains[i])) {
  //       designPresent = true;
  //     }
  //   }
  //   if (techPresent && designPresent) {
  //     toastError("Cannot take tech and design");
  //     return;
  //   }
  //   setbtnLoading(true);
  //   if (status === "ER" || status === "RR") {
  //     await postRound1Info(
  //       token,
  //       comment,
  //       status,
  //       ["Unknown"],
  //       userDetails.user.regNo,
  //       "0"
  //     )
  //       .then((data) => {
  //         console.log(data);
  //         setbtnLoading(false);
  //         toastSuccess("Submitted Round1 Successfully!");
  //         setTimeout(() => {
  //           history.push("/round1");
  //         }, 2000);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         toastError("Error submitting round1 form");
  //         setbtnLoading(false);
  //       });
  //     return;
  //   }
  //   if (
  //     comment !== "" &&
  //     comment != null &&
  //     status !== "" &&
  //     status != null &&
  //     selectedDomains.length != 0 &&
  //     projectGiven != ""
  //   ) {
  //     console.log("Assigned project puid->", projectGiven);
  //     await postRound1Info(
  //       token,
  //       comment,
  //       status,
  //       [...selectedDomains],
  //       userDetails.user.regNo,
  //       projectGiven
  //     )
  //       .then((data) => {
  //         console.log(data);
  //         setbtnLoading(false);
  //         toastSuccess("Submitted Round1 Successfully!");
  //         // window.location.reload();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         toastError("Error submitting round1 form");
  //         setbtnLoading(false);
  //       });
  //   } else {
  //     setbtnLoading(false);
  //     toastError("Please check all the details carefully");
  //   }
  // };

  // const round1 = () => {
  //   if (userDetails.round1Data.meetingCompleted) {
  //     const round1Data = userDetails.round1Data;
  //     const round2Data = userDetails.round2Data;
  //     return (
  //       <div>
  //         <div className="user-detail-container">
  //           <div className="user-detail-heading">{"Exception"}</div>
  //           <div className="user-detail">{round1Data.exception.toString()}</div>
  //         </div>
  //         <div className="user-detail-container">
  //           <div className="user-detail-heading">{"Status"}</div>
  //           <div className="user-detail">{round1Data.status}</div>
  //         </div>
  //         <div className="user-detail-container">
  //           <div className="user-detail-heading">{"Comment"}</div>
  //           <div className="user-detail">{round1Data.Comment.comment}</div>
  //         </div>
  //         <div className="user-detail-container">
  //           <div className="user-detail-heading">{"Assigned Project"}</div>
  //           <div className="user-detail">
  //             {userDetails.user.Project == null
  //               ? "none"
  //               : userDetails.user.Project.title === null
  //               ? "none"
  //               : userDetails.user.Project.title}
  //           </div>
  //         </div>
  //         <div className="user-detail-container">
  //           <div className="user-detail-heading">
  //             {"Admin who took the interview"}
  //           </div>
  //           <div className="user-detail">
  //             {round1Data.Admin != null
  //               ? round1Data.Admin.name ?? "Unknown"
  //               : "Unknown"}
  //           </div>
  //         </div>
  //         <div className="user-detail-container">
  //           <div className="user-detail-heading">{"Accepted Domains"}</div>
  //           <div className="user-detail">
  //             {round2Data.map((data) => {
  //               return <div>{data.specificDomain + ", "}</div>;
  //             })}
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }

  //   //setcurrRound(1);

  //   const arrayHasElement = (arr, ele) => {
  //     for (let i = 0; i < arr.length; i++) {
  //       if (arr[i] === ele) {
  //         return true;
  //       }
  //     }

  //     return false;
  //   };

  //   const deleteElementFromArray = (arr, ele) => {
  //     let newArr = [];
  //     for (let i = 0; i < arr.length; i++) {
  //       if (arr[i] !== ele) {
  //         newArr.push(arr[i]);
  //       }
  //     }

  //     return newArr;
  //   };

  //   const handleProjectSelectChange = (e) => {
  //     console.log(e.target.value);
  //     setprojectGiven(e.target.value);
  //   };

  //   let domains = userDetails.user.specificDomains;
  //   if (domains == null) {
  //     domains = [];
  //   }
  //   return (
  //     <div className="roundn-main-container">
  //       <div className="roundn-container">
  //         <div className="roundn-details-container">
  //           <div className="roundn-details-heading">COMMENTS</div>
  //           <textarea
  //             cols={50}
  //             rows={10}
  //             value={comment}
  //             onChange={(e) => {
  //               setComment(e.target.value);
  //             }}
  //           ></textarea>
  //         </div>
  //         <div className="roundn-choice-container">
  //           <div className="roundn-status-container">
  //             <div className="choice-container">
  //               <input
  //                 type={"radio"}
  //                 name="status"
  //                 value={"yes"}
  //                 checked={status === "AR"}
  //                 onChange={() => setStatus("AR")}
  //               />{" "}
  //               <br />
  //               <label>Yes</label>
  //             </div>
  //             <div className="choice-container">
  //               <input
  //                 type={"radio"}
  //                 name="status"
  //                 value={"no"}
  //                 checked={status === "RR"}
  //                 onChange={() => setStatus("RR")}
  //               />{" "}
  //               <br />
  //               <label>No</label>
  //             </div>
  //             <div className="choice-container">
  //               <input
  //                 type={"radio"}
  //                 name="status"
  //                 value={"idk"}
  //                 checked={status === "ER"}
  //                 onChange={() => setStatus("ER")}
  //               />{" "}
  //               <br />
  //               <label>I dont know</label>
  //             </div>
  //           </div>
  //           <div className="roundn-status-container">
  //             {domains.map((data) => {
  //               return (
  //                 <div className="choice-container">
  //                   <input
  //                     type="checkbox"
  //                     checked={arrayHasElement(selectedDomains, data)}
  //                     onChange={() => {
  //                       if (!arrayHasElement(selectedDomains, data)) {
  //                         let newSelectedDomains = [...selectedDomains];
  //                         newSelectedDomains.push(data);
  //                         setselectedDomains(newSelectedDomains);
  //                       } else {
  //                         if (selectedDomains.size === 1) {
  //                           setselectedDomains([]);
  //                         } else {
  //                           let newSelectedDomains = deleteElementFromArray(
  //                             selectedDomains,
  //                             data
  //                           );
  //                           setselectedDomains(newSelectedDomains);
  //                         }
  //                       }
  //                     }}
  //                   />{" "}
  //                   <br />
  //                   <label>{data}</label>
  //                 </div>
  //               );
  //             })}
  //           </div>
  //           <div className="roundn-status-container">
  //             <select value={projectGiven} onChange={handleProjectSelectChange}>
  //               {projects.map((project, i) => {
  //                 return (
  //                   <option key={i} value={project.puid}>
  //                     {project.title}
  //                   </option>
  //                 );
  //               })}
  //             </select>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="amc-submit-btn-container">
  //         <button
  //           type={"button"}
  //           disabled={btnLoading}
  //           className="amc-submit-button"
  //           onClick={submitRound1Info}
  //         >
  //           Submit
  //         </button>
  //       </div>
  //     </div>
  //   );
  // };
  const [round2TechProjectGiven, setRound2TechProjectGiven] = useState("");
  const submitRound2Info = async (coreDomain, specificDomain) => {
    if (btnLoading) {
      return;
    }
    if (projectGiven === -1) {
      toastError("Please select a project");
      return;
    }
    setbtnLoading(true);

    if (comment !== "" && comment != null && status !== "" && status != null) {
      await postRound2Info(
        token,
        comment,
        status,
        userDetails.user.regNo,
        coreDomain,
        projectGiven
      )
        .then((data) => {
          console.log(data);
          setbtnLoading(false);
          toastSuccess("Round2 Data registered!");
          //window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          toastError(err.response.data.message);
          setbtnLoading(false);
        });
    } else {
      setbtnLoading(false);
      toastError("Please check all the details carefully");
    }
  };

  const round2TD = () => {
    if (currRound === 1) {
      return <div>Not reached this round</div>;
    }

    let currDomain;
    let managementPresent = false;
    let techDesPresent = false;
    // let managementComment = "";
    let managementCurrDomain;
    for (let i = 0; i < userDetails.round2Data.length; i++) {
      let data = userDetails.round2Data[i];
      console.log("Data: 1", data);
      if (data.coreDomain === "TechDesign") {
        techDesPresent = true;
        currDomain = data;
        break;
      } else if (data.coreDomain === "Management") {
        managementPresent = true;
        // managementComment = data.Comment.comment;
        managementCurrDomain = data;
        if (userDetails.project) {
          for (let i = 0; i < userDetails.project.length; i++) {
            let project = userDetails.project[i];
            if (project.projectSubmissions.coreDomain === "Management") {
              setRound2ManagementProjectGiven(project.projectSubmissions.puid);
            }
          }
        }
      }
    }
    if (currDomain == null) {
      return <div>Neither selected for tech nor for design</div>;
    }
    userDetails.project.map((project) => {
      if (project.projectSubmissions.coreDomain === "TechDesign") {
        setRound2TechProjectGiven(project.projectSubmissions.puid);
      }
      if (project.projectSubmissions.coreDomain === "Management") {
        setRound2ManagementProjectGiven(project.projectSubmissions.puid);
      }
    });
    if (
      techDesPresent &&
      managementPresent &&
      managementCurrDomain.meetingCompleted &&
      currDomain.meetingCompleted
    ) {
      const round2Data = currDomain;
      return (
        <>
          <div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Exception"}</div>
              <div className="user-detail">
                {currDomain.exception.toString()}
              </div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Status"}</div>
              <div className="user-detail">{currDomain.status}</div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Comment"}</div>
              <div className="user-detail">{currDomain.Comment.comment}</div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Accepted Domains"}</div>
              <div className="user-detail">{round2Data.specificDomain}</div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">
                {"Admin who took the interview"}
              </div>
              <div className="user-detail">{currDomain.Admin.name}</div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Project"}</div>
              {userDetails.project.map((project) => {
                return (
                  <div className="user-detail">
                    {project.projectSubmissions.coreDomain}:{" "}
                    {project.projectDetails.title}
                  </div>
                );
              })}
              {/* <div className="user-detail">{userDetails.user.Project.title}</div> */}
            </div>
          </div>

          <div>
            <h2>Management</h2>
          </div>
          <div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Exception"}</div>
              <div className="user-detail">
                {managementCurrDomain.exception.toString()}
              </div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Status"}</div>
              <div className="user-detail">{managementCurrDomain.status}</div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Comment"}</div>
              <div className="user-detail">
                {managementCurrDomain.Comment.comment}
              </div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Accepted Domains"}</div>
              <div className="user-detail">
                {managementCurrDomain.specificDomain}
              </div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">
                {"Admin who took the interview"}
              </div>
              <div className="user-detail">
                {managementCurrDomain.Admin.name}
              </div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Project"}</div>
              {userDetails.project.map((project) => {
                return (
                  <div className="user-detail">
                    {project.projectSubmissions.coreDomain}:{" "}
                    {project.projectDetails.title}
                  </div>
                );
              })}
              {/* <div className="user-detail">{userDetails.user.Project.title}</div> */}
            </div>
          </div>
        </>
      );
    } else if (currDomain.meetingCompleted) {
      const round2Data = currDomain;
      return (
        <div>
          <div className="user-detail-container">
            <div className="user-detail-heading">{"Exception"}</div>
            <div className="user-detail">{currDomain.exception.toString()}</div>
          </div>
          <div className="user-detail-container">
            <div className="user-detail-heading">{"Status"}</div>
            <div className="user-detail">{currDomain.status}</div>
          </div>
          <div className="user-detail-container">
            <div className="user-detail-heading">{"Comment"}</div>
            <div className="user-detail">{currDomain.Comment.comment}</div>
          </div>
          <div className="user-detail-container">
            <div className="user-detail-heading">{"Accepted Domains"}</div>
            <div className="user-detail">{round2Data.specificDomain}</div>
          </div>
          <div className="user-detail-container">
            <div className="user-detail-heading">
              {"Admin who took the interview"}
            </div>
            <div className="user-detail">{currDomain.Admin.name}</div>
          </div>
          <div className="user-detail-container">
            <div className="user-detail-heading">{"Project"}</div>
            {userDetails.project.map((project) => {
              return (
                <div className="user-detail">
                  {project.projectSubmissions.coreDomain}:{" "}
                  {project.projectDetails.title}
                </div>
              );
            })}
            {/* <div className="user-detail">{userDetails.user.Project.title}</div> */}
          </div>
        </div>
      );
    }
    const handleProjectSelectChange = (e) => {
      console.log("Project Select: ", e.target.value);
      setprojectGiven(e.target.value);
    };

    //setcurrRound(2);
    return (
      <div className="roundn-main-container">
        <div className="roundn-container">
          <div className="roundn-details-container">
            <div className="roundn-details-heading">COMMENTS</div>
            <textarea
              cols={50}
              rows={10}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="roundn-choice-container">
            <div className="roundn-status-container">
              <div className="choice-container">
                <input
                  type={"radio"}
                  name="status"
                  value={"yes"}
                  checked={status === "AR"}
                  onChange={() => setStatus("AR")}
                />{" "}
                <br />
                <label>Yes</label>
              </div>
              <div className="choice-container">
                <input
                  type={"radio"}
                  name="status"
                  value={"no"}
                  checked={status === "RR"}
                  onChange={() => setStatus("RR")}
                />{" "}
                <br />
                <label>No</label>
              </div>
              <div className="choice-container">
                <input
                  type={"radio"}
                  name="status"
                  value={"idk"}
                  checked={status === "ER"}
                  onChange={() => setStatus("ER")}
                />{" "}
                <br />
                <label>I dont know</label>
              </div>
            </div>
            <div className="roundn-status-container">
              {round2TechProjectGiven ? (
                <>
                  <div className="user-detail-container">
                    <div className="user-detail-heading">{"Project"}</div>
                    {userDetails.project.map((project) => {
                      return (
                        <div className="user-detail">
                          {project.projectSubmissions.coreDomain}:{" "}
                          {project.projectDetails.title}
                        </div>
                      );
                    })}
                    {/* <div className="user-detail">{userDetails.user.Project.title}</div> */}
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <select
                    value={projectGiven}
                    onChange={handleProjectSelectChange}
                  >
                    <option value={-1} disabled selected>
                      Select an Option
                    </option>
                    {projects !== null && projects.length !== 0 ? (
                      projects.map((project, i) => {
                        return (
                          <option key={i} value={project.puid}>
                            {project.title}
                          </option>
                        );
                      })
                    ) : (
                      <>No Projects</>
                    )}
                  </select>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="amc-submit-btn-container">
          <button
            type={"button"}
            disabled={btnLoading}
            className="amc-submit-button"
            onClick={() =>
              submitRound2Info(currDomain.coreDomain, currDomain.specificDomain)
            }
          >
            Submit
          </button>
        </div>
      </div>
    );
  };

  const submitRound3Info = async () => {
    if (btnLoading) {
      return;
    }
    setbtnLoading(true);
    if (comment !== "" && comment != null && status !== "" && status != null) {
      await postRound3Info(token, comment, status, userDetails.user.regNo)
        .then((data) => {
          console.log(data);
          setbtnLoading(false);
          toastSuccess("Round3 data registered!");
          //window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          toastError(err.response.data.message);
          setbtnLoading(false);
        });
    } else {
      setbtnLoading(false);
      toastError("Please check all the details carefully");
    }
  };

  const round3 = () => {
    console.log("Current Round: ", currRound);
    if (currRound !== 3) {
      return <div>Not reached this round</div>;
    }

    //setcurrRound(3);

    if (userDetails.round3Data.meetingCompleted) {
      const round3Data = userDetails.round3Data;
      return (
        <div>
          <div className="user-detail-container">
            <div className="user-detail-heading">{"Exception"}</div>
            <div className="user-detail">{round3Data.exception.toString()}</div>
          </div>
          <div className="user-detail-container">
            <div className="user-detail-heading">{"Status"}</div>
            <div className="user-detail">{round3Data.status}</div>
          </div>
          <div className="user-detail-container">
            <div className="user-detail-heading">{"Accepted Domains"}</div>
            <div className="user-detail">{round3Data.specificDomain}</div>
          </div>
          <div className="user-detail-container">
            <div className="user-detail-heading">
              {"Admin who took the interview"}
            </div>
            <div className="user-detail">{round3Data.Admin.name}</div>
          </div>
          <div className="user-detail-container">
            <div className="user-detail-heading">{"Comments"}</div>
            <div className="user-detail">{round3Data.Comment.comment}</div>
          </div>
        </div>
      );
    }

    console.log("------------");
    console.log(round2Management);
    console.log(currRound);

    return (
      <div className="roundn-main-container">
        <div className="roundn-container">
          <div className="roundn-details-container">
            <div className="roundn-details-heading">COMMENTS</div>
            <textarea
              cols={50}
              rows={10}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="roundn-choice-container">
            <div className="roundn-status-container">
              <div className="choice-container">
                <input
                  type={"radio"}
                  name="status"
                  value={"yes"}
                  checked={status === "AR"}
                  onChange={() => setStatus("AR")}
                />{" "}
                <br />
                <label>Yes</label>
              </div>
              <div className="choice-container">
                <input
                  type={"radio"}
                  name="status"
                  value={"no"}
                  checked={status === "RR"}
                  onChange={() => setStatus("RR")}
                />{" "}
                <br />
                <label>No</label>
              </div>
              <div className="choice-container">
                <input
                  type={"radio"}
                  name="status"
                  value={"idk"}
                  checked={status === "ER"}
                  onChange={() => setStatus("ER")}
                />{" "}
                <br />
                <label>I dont know</label>
              </div>
            </div>
          </div>
        </div>
        <div className="amc-submit-btn-container">
          <button
            type={"button"}
            disabled={btnLoading}
            className="amc-submit-button"
            onClick={submitRound3Info}
          >
            Submit
          </button>
        </div>
        {round2Management != null && currRound === 3 ? (
          <div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Exception"}</div>
              <div className="user-detail">
                {round2Management.exception.toString()}
              </div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Status"}</div>
              <div className="user-detail">{round2Management.status}</div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Accepted Domains"}</div>
              <div className="user-detail">
                {round2Management.specificDomain}
              </div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">
                {"Admin who took the interview"}
              </div>
              <div className="user-detail">{round2Management.Admin.name}</div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Comments"}</div>
              <div className="user-detail">
                {round2Management.Comment.comment}
              </div>
            </div>
            <div className="user-detail-container">
              <div className="user-detail-heading">{"Project"}</div>
              {userDetails.project.map((project) => {
                return (
                  <div className="user-detail">
                    {project.projectSubmissions.coreDomain}:{" "}
                    {project.projectDetails.title}
                  </div>
                );
              })}
              {/* <div className="user-detail">{userDetails.user.Project.title}</div> */}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };
  const [round2ManagementProjectGiven, setRound2ManagementProjectGiven] =
    useState("");
  const submitRound2ExpManagement = async () => {
    if (btnLoading) {
      return;
    }
    setbtnLoading(true);
    if (comment !== "" && comment != null && status !== "" && status != null) {
      console.log("Round 2 Management", round2ManagementProjectGiven);
      await gdaSubmitCandidateInfo(
        token,
        comment,
        status,
        params.regNo,
        round2ManagementProjectGiven
      )
        .then((data) => {
          console.log(data);
          setbtnLoading(false);
          toastSuccess("Round2 management data registered!");
          //window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          toastError(err.response.data.message);
          setbtnLoading(false);
        });
    } else {
      setbtnLoading(false);
      toastError("Please check all the details carefully");
    }
  };

  const exceptionTab = () => {
    let exceptionComment = "";
    let exceptionCommentManagement = "";
    // if (currRound === 2 && userDetails.round2Data.status === "ER") {
    //   exceptionComment = userDetails.round2Data.Comment.comment;
    // }

    console.log("User Details: ", userDetails);
    if (currRound === 3 && userDetails.round3Data.status === "ER") {
      exceptionComment = userDetails.round3Data.Comment.comment;
    }
    let managementPresent = false;
    let techDesPresent = false;
    if (currRound === 2) {
      for (let i = 0; i < userDetails.round2Data.length; i++) {
        let data = userDetails.round2Data[i];
        if (data.coreDomain === "TechDesign" && data.status === "ER") {
          techDesPresent = true;
          exceptionComment = data.Comment.comment;
          break;
        } else if (data.coreDomain === "Management" && data.status === "ER") {
          managementPresent = true;
          exceptionCommentManagement = data.Comment.comment;
          if (userDetails.project) {
            for (let i = 0; i < userDetails.project.length; i++) {
              let project = userDetails.project[i];
              if (project.projectSubmissions.coreDomain === "Management") {
                setRound2ManagementProjectGiven(
                  project.projectSubmissions.puid
                );
              }
            }
          }
        }
      }
      // if (techDesPresent || managementPresent) {
      //   exceptionComment = userDetails.round2Data.Comment.comment;
      // }
      return (
        <div>
          {exceptionComment === "" && exceptionCommentManagement === "" ? (
            <div>{"Nothing to see here"}</div>
          ) : (
            <>
              <div>{"Round No: " + currRound + " exception comment"}</div>
              <h4>{"Comment: " + exceptionComment}</h4>
              <h4>{"Management Comment: " + exceptionCommentManagement}</h4>
            </>
          )}
          {managementPresent ? (
            <div className="roundn-main-container">
              <div className="roundn-container">
                <div className="roundn-details-container">
                  <div className="roundn-details-heading">COMMENTS</div>
                  <textarea
                    cols={50}
                    rows={10}
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="roundn-choice-container">
                  <div className="roundn-status-container">
                    <div className="choice-container">
                      <input
                        type={"radio"}
                        name="status"
                        value={"yes"}
                        checked={status === "AR"}
                        onChange={() => setStatus("AR")}
                      />{" "}
                      <br />
                      <label>Yes</label>
                    </div>
                    <div className="choice-container">
                      <input
                        type={"radio"}
                        name="status"
                        value={"no"}
                        checked={status === "RR"}
                        onChange={() => setStatus("RR")}
                      />{" "}
                      <br />
                      <label>No</label>
                    </div>
                    <div className="choice-container">
                      <input
                        type={"radio"}
                        name="status"
                        value={"idk"}
                        checked={status === "ER"}
                        onChange={() => setStatus("ER")}
                      />{" "}
                      <br />
                      <label>I dont know</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-detail-container">
                <div className="user-detail-heading">{"Project"}</div>
                {userDetails.project.map((project) => {
                  return (
                    <div className="user-detail">
                      {project.projectSubmissions.coreDomain}:{" "}
                      {project.projectDetails.title}
                    </div>
                  );
                })}
                {/* <div className="user-detail">{userDetails.user.Project.title}</div> */}
              </div>
              <div className="amc-submit-btn-container">
                <button
                  type={"button"}
                  disabled={btnLoading}
                  className="amc-submit-button"
                  onClick={submitRound2ExpManagement}
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    }
    return (
      <div>
        {exceptionComment === "" ? (
          <div>{"Nothing to see here"}</div>
        ) : (
          <>
            <div>{"Round No.: " + currRound + " exception comment"}</div>
            <h4>{"Comment: " + exceptionComment}</h4>
          </>
        )}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="amcscreen-container center-loading">
        <Loader type="ThreeDots" color="#067EF5" />
      </div>
    );
  }
  if (userDetails == null || userDetails.user == null) {
    return <div>Invalid candidate</div>;
  }
  return (
    <div className="amcscreen-container">
      <Navbar />
      <div className="details-container">
        <div className="user-detail-container">
          <div className="user-detail-heading">NAME</div>
          <div className="user-detail">
            {userDetails == null
              ? "none"
              : userDetails.user == null
              ? "none"
              : userDetails.user.name ?? "none"}
          </div>
        </div>
        <div className="user-detail-container">
          <div className="user-detail-heading">DOMAINS</div>
          <div className="user-detail">
            {userDetails.user.specificDomains ? (
              userDetails.user.specificDomains.map((data) => {
                return <div>{data + ", "}</div>;
              })
            ) : (
              <div>{"none"}</div>
            )}
          </div>
        </div>
        <div className="user-detail-container">
          <div className="user-detail-heading">REGISTRATION NUMBER</div>
          <div className="user-detail">{userDetails.user.regNo ?? "none"}</div>
        </div>
        <div className="user-detail-container">
          <div className="user-detail-heading">PHONE NUMBER</div>
          <div className="user-detail">
            {userDetails.user.phoneNo ?? "none"}
          </div>
        </div>
        <div className="user-detail-container">
          <div className="user-detail-heading">EMAIL</div>
          <div className="user-detail">{userDetails.user.email ?? "none"}</div>
        </div>
        <div className="user-detail-container">
          <div className="user-detail-heading">LINKS PROVIDED</div>
          {userDetails.user.ProjectSubmissions.map((project) => {
            return (
              <div className="user-detail">
                {project.coreDomain}: {project.projectLink ?? "none"}
              </div>
            );
          })}
        </div>
      </div>
      {/* <Tabbar round0={round0} round1={round1} round2TD={round2TD} round3 = {round3} exceptionTab={exceptionTab}/> */}
      <Tabbar
        round0={round0}
        round2TD={round2TD}
        round3={round3}
        exceptionTab={exceptionTab}
      />
    </div>
  );
};
export default AmcScreen;
