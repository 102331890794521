import activityLayerApi from "../apis/apiSetup.js";

export const bookSlotRound3 = (token, regNo) => {
  return new Promise((resolve, reject) => {
    activityLayerApi
      .post(
        `/api/admin/r3/selectRound3Candidate`,
        {
          regNo,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
