import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import cookie from "react-cookies";

import "./MeetScreen.css";

const MeetScreen = () => {
  const [link, setLink] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleSubmit = () => {
    setIsSubmitLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin`,
        {
          meetLink: link,
        },
        {
          headers: {
            Authorization: `Bearer ${cookie.load("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.data[0] === 1) {
          window.location.href = "/dashboard";
        } else {
          alert("Unsuccessful request. Try again.");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="meetscreen-container">
      <Navbar />
      <div className="meetscreen-enter-container">
        <p>Enter your meet link here:</p>
        <input
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
        <button onClick={handleSubmit} className="meetscreen-submit-buttton">
          {isSubmitLoading ? <CircularProgress /> : <>Submit</>}
        </button>
      </div>
    </div>
  );
};

export default MeetScreen;
