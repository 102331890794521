import activityLayerApi from "../apis/apiSetup.js";

export const getUserAmcDetails = (regNo, token) => {
  return new Promise((resolve, reject) => {
    activityLayerApi
      .get(`/api/admin/amc/fetchAmc?regNo=${regNo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
        reject("Kuch to gadbad hai daya!");
      });
  });
};

export const getProjects = (token, coreDomain) => {
  return new Promise((resolve, reject) => {
    activityLayerApi
      .get(`/api/admin/projects?domain=${coreDomain}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
        reject("Kuch to gadbad hai daya!");
      });
  });
};

export const postRound1Info = (
  token,
  comment,
  status,
  eligibleDomains,
  regNo,
  puid
) => {
  return new Promise((resolve, reject) => {
    activityLayerApi
      .post(
        `/api/admin/amc/round1interview`,
        {
          comment,
          status,
          eligibleDomains,
          regNo,
          puid: puid.toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp);
      })
      .catch((err) => {
        console.log(err);
        reject("Kuch to gadbad hai daya!");
      });
  });
};

export const postRound2Info = (
  token,
  comment,
  status,
  regNo,
  coreDomain,
  puid
) => {
  console.log({
    comment,
    status,
    regNo,
    coreDomain,
    puid,
  });
  return new Promise((resolve, reject) => {
    activityLayerApi
      .post(
        `/api/admin/amc/round2interview`,
        {
          comment,
          status,
          regNo,
          coreDomain,
          puid: puid.toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
        reject("Kuch to gadbad hai daya!");
      });
  });
};

export const postRound3Info = (token, comment, status, regNo) => {
  console.log({
    comment,
    status,
    regNo,
  });
  return new Promise((resolve, reject) => {
    activityLayerApi
      .post(
        `/api/admin/amc/round3interview`,
        {
          comment,
          status,
          regNo,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
        reject("Kuch to gadbad hai daya!");
      });
  });
};

export const getOngoingMeetings = (token) => {
  return new Promise((resolve, reject) => {
    activityLayerApi
      .get(
        `/api/admin/r2/nonmgmt/ongoing?coreDomain=TechDesign&coreDomain=Management`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp.data.data);
      })
      .catch((err) => {
        reject("Unauthorized");
      });
  });
};
export const getOngoingMeetingsR3 = (token) => {
  return new Promise((resolve, reject) => {
    activityLayerApi
      .get(`/api/admin/r3/ongoing`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp.data.data);
      })
      .catch((err) => {
        reject("Unauthorized");
      });
  });
};
