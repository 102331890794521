import activityLayerApi from "../apis/apiSetup.js";

export const bookSlotRound2TD = (token, regNo) => {
  return new Promise((resolve, reject) => {
    activityLayerApi
      .post(
        `/api/admin/r2/emailCandidate`,
        {
          regNo,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
        reject("Kuch to gadbad hai daya!");
      });
  });
};

export const gdaGetCandidates = (token, auid) => {
  return new Promise((resolve, reject) => {
    activityLayerApi
      .get(`/api/admin/r2/mgmt/fetchGdaCandidates?auid=${auid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
        reject("Kuch to gadbad hai daya!");
      });
  });
};

export const gdaSubmitCandidateInfo = (token, comment, status, regNo, puid) => {
  return new Promise((resolve, reject) => {
    activityLayerApi
      .post(
        `/api/admin/amc/round2Interview`,
        {
          regNo,
          status,
          comment,
          coreDomain: "Management",
          specificDomain: "Management",
          puid: puid.toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => {
        if (!resp.data.success) {
          throw new Error("Request failed");
        }
        resolve(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
        reject("Kuch to gadbad hai daya!");
      });
  });
};
