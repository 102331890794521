import React, { useState } from "react";
import axios from "axios";
import cookie from "react-cookies";
import { CircularProgress } from "@material-ui/core";
import { toastError, toastSuccess } from "../../components/toasts/toasts";

import "./LoginScreen.css";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleSubmit = () => {
    setIsSubmitLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/adminlogin`, {
        email,
        password,
      })
      .then((response) => {
        console.log(response.data.data.user.isActive);
        if (response.data.data.user.isActive === true) {
          cookie.save("token", response.data.data.token, { path: "/" });
          window.location.href = "/meet";
          console.log(true);
        } else {
          toastError(response.data.message);
        }
      })
      .catch((e) => {
        toastError(e.response.data.message);
        console.log(e);
      });
  };

  return (
    <div className="loginpage-container">
      <h2>Login page</h2>
      <div className="loginpage-login-container">
        <input
          type="text"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleSubmit} style={{ cursor: "pointer" }}>
          {isSubmitLoading ? <CircularProgress /> : <>Log In</>}
        </button>

        <a href="/signup">Signup page</a>
      </div>
    </div>
  );
};

export default LoginScreen;
