import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Navbar from "../../components/Navbar/Navbar";
import { toastError, toastSuccess } from "../../components/toasts/toasts";
import Loader from "react-loader-spinner";
import { bookSlotRound3 } from "../../apis/round3Api";

import "./RoundThreeListScreen.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.paper,
    // backgroundColor: 'black',
    // width: 500,
    primary: "blue",
  },
}));

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "240px",
    background: "black",
  },
  overlay: {
    background: "transparent",
  },
};

const RoundThreeListScreen = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [unscheduledTDCandidates, setUnscheduledTDCandidates] = useState([]);
  const [selectedCandidate, setselectedCandidate] = useState(null);
  const [scheduledTDCandidates, setScheduledTDCandidates] = useState([]);
  const [myTDCandidates, setMyTDCandidates] = useState([]);
  const history = useHistory();
  const token = cookie.load("token");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  useEffect(() => {
    getUnscheduledTDCandidates();
    getScheduledTDCandidates();
    getMyTDCandidates();
  }, []);

  const getUnscheduledTDCandidates = () => {
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `Bearer ${cookie.load("token")}` },
    };
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/r3/fetchRound3Candidates`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setUnscheduledTDCandidates(response.data);
        console.log(response);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const getScheduledTDCandidates = () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `Bearer ${cookie.load("token")}` },
    };
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/r3/ongoing`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("Scheduled TD ppl->", response);
        setScheduledTDCandidates(response.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const getMyTDCandidates = () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `Bearer ${cookie.load("token")}` },
    };
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/r3/fetchMeetings`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("My TD ppl->", response);
        setMyTDCandidates(response.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };
  const scheduleCandidateInterview = (regNo) => {
    console.log(regNo);
    history.push("/amc/regNo=" + regNo);
  };
  const interviewCandidate = async (regNo) => {
    console.log("Interview Candidate");
    await bookSlotRound3(token, regNo)
      .then((response) => {
        console.log("response of bookslot3", response);
        if (!response.status) {
          toastSuccess(response.message);
          window.location.reload();
        } else {
          toastError(response.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const sendToAMC = (regNo) => {
    console.log(regNo);
    history.push("/amc/regNo=" + regNo);
  };

  if (loading) {
    return (
      <div className="roundonescreen-container center-loading">
        <Loader type="ThreeDots" color="#067EF5" />
      </div>
    );
  } else {
    return (
      <div className="roundthreelistscreen-container">
        <Navbar />
        <h2>Round 3 Candidates</h2>
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Unscheduled" {...a11yProps(0)} />
              <Tab label="Scheduled" {...a11yProps(1)} />
              <Tab label="My list" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <table className="candidate-table">
                <thead>
                  <tr>
                    <th className="candidate-table-row-title">Name</th>
                    <th className="candidate-table-row-title">Reg No</th>
                    <th className="candidate-table-row-title">Phone No</th>
                    <th className="candidate-table-row-title">Domain</th>
                    {/* <th className="candidate-table-row-title">Project title</th>
                    <th className="candidate-table-row-title">Project link</th> */}
                    <th className="candidate-table-row-title">Slot</th>
                    <th className="candidate-table-row-title">
                      Take Interview
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {unscheduledTDCandidates ? (
                    unscheduledTDCandidates.map((candidate, index) => (
                      <tr key={index} className="candidate-table-row">
                        <td className="candidate-table-row-data">
                          {candidate.User.name}
                        </td>
                        <td className="candidate-table-row-data">
                          {candidate.User.regNo}
                        </td>
                        <td className="candidate-table-row-data">
                          {candidate.User.phoneNo}
                        </td>
                        <td className="candidate-table-row-data">
                          {candidate.User.specificDomains[0] +
                            ", " +
                            candidate.User.specificDomains[1]}
                        </td>
                        {/* <td className="candidate-table-row-data">
                          {candidate.User.Project.title}
                        </td>
                        <td className="candidate-table-row-data">
                          <a
                            href={candidate.User.projectLink}
                            target="_blank"
                            rel="noopener"
                          >
                            Project
                          </a>
                        </td> */}
                        <td className="candidate-table-row-data">
                          {candidate.Slot
                            ? candidate.Slot.date +
                              " " +
                              candidate.Slot.timeFrom +
                              "-" +
                              candidate.Slot.timeTo
                            : "Something wrong"}
                        </td>
                        <td className="candidate-table-row-data">
                          <button
                            className="candidate-table-row-data-interview"
                            onClick={() => {
                              console.log(candidate);
                              setselectedCandidate(candidate);
                              interviewCandidate(candidate.regNo);
                            }}
                          >
                            Take Interview
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div>No slots found</div>
                  )}
                </tbody>
              </table>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <table className="candidate-table">
                <thead>
                  <tr>
                    <th className="candidate-table-row-title">Name</th>
                    <th className="candidate-table-row-title">Reg No</th>
                    <th className="candidate-table-row-title">Domain</th>
                    <th className="candidate-table-row-title">Admin</th>
                    {/* <th className="candidate-table-row-title">Project link</th> */}
                    <th className="candidate-table-row-title">Meet link</th>
                    <th className="candidate-table-row-title">Slot</th>
                    <th className="candidate-table-row-title">
                      Interview link
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {scheduledTDCandidates ? (
                    scheduledTDCandidates.map((candidate, index) => (
                      <tr key={index} className="candidate-table-row">
                        <td className="candidate-table-row-data">
                          {candidate.User.name}
                        </td>
                        <td className="candidate-table-row-data">
                          {candidate.regNo}
                        </td>
                        <td className="candidate-table-row-data">
                          {candidate.User.specificDomains[0] +
                            ", " +
                            candidate.User.specificDomains[1]}
                        </td>
                        <td className="candidate-table-row-data">
                          {candidate.Admin.name}
                        </td>
                        {/* <td className="candidate-table-row-data">
                          <a
                            href={candidate.User.projectLink}
                            target="_blank"
                            rel="noopener"
                          >
                            Project
                          </a>
                        </td> */}
                        <td className="candidate-table-row-data">
                          <a
                            href={candidate.Admin.meetLink}
                            target="_blank"
                            rel="noopener"
                          >
                            Meet Link
                          </a>
                        </td>
                        <td className="candidate-table-row-data">
                          {candidate.Slot
                            ? candidate.Slot.date +
                              " " +
                              candidate.Slot.timeFrom +
                              "-" +
                              candidate.Slot.timeTo
                            : "Something wrong"}
                        </td>
                        <td className="candidate-table-row-data">
                          <button
                            className="candidate-table-row-data-interview"
                            onClick={() =>
                              scheduleCandidateInterview(candidate.regNo)
                            }
                          >
                            Interview
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div>No slots found</div>
                  )}
                </tbody>
              </table>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <table className="candidate-table">
                <thead>
                  <tr>
                    <th className="candidate-table-row-title">Name</th>
                    <th className="candidate-table-row-title">Domain</th>
                    {/* <th className="candidate-table-row-title">Project link</th> */}
                    <th className="candidate-table-row-title">Slot</th>
                    <th className="candidate-table-row-title">
                      Interview link
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {myTDCandidates ? (
                    myTDCandidates.map((candidate, index) => (
                      <tr key={index} className="candidate-table-row">
                        <td className="candidate-table-row-data">
                          {candidate.User.name}
                        </td>
                        <td className="candidate-table-row-data">
                          {candidate.User.specificDomains[0] +
                            ", " +
                            candidate.User.specificDomains[1]}
                        </td>
                        {/* <td className="candidate-table-row-data">
                          <a
                            href={candidate.User.projectLink}
                            target="_blank"
                            rel="noopener"
                          >
                            Project
                          </a>
                        </td> */}
                        <td className="candidate-table-row-data">
                          {candidate.Slot
                            ? candidate.Slot.date +
                              " " +
                              candidate.Slot.timeFrom +
                              "-" +
                              candidate.Slot.timeTo
                            : "Something wrong"}
                        </td>
                        <td className="candidate-table-row-data">
                          <button
                            className="candidate-table-row-data-interview"
                            onClick={() => sendToAMC(candidate.User.regNo)}
                          >
                            Interview
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div>No slots found</div>
                  )}
                </tbody>
              </table>
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    );
  }
};

export default RoundThreeListScreen;
